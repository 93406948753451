import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.css']
})
export class RechercheComponent implements OnInit {

  listCmds: any = []
  statusCmds: any = []

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }
  
  async listCmdsClient(keyword) {
    if (keyword.value != "") {
      await this.cmdsService.searchCmd(null, keyword.value).then((res: any) => {
        // console.log(res)
        if (res) {
          this.listCmds = res;
        }
      });
    }
  }

}
