import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetailsVirementComponent } from 'src/app/components/shared/dialogs/details-virement/details-virement.component';
import { ClientsService } from 'src/app/services/clients/clients.service';

@Component({
  selector: 'app-demande-client',
  templateUrl: './demande-client.component.html',
  styleUrls: ['./demande-client.component.css']
})
export class DemandeClientComponent implements OnInit {

  listDemandes: any = []
  selectedVirement: number = -1

  constructor(private clientService: ClientsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.allDemandesClient()
  }


  async allDemandesClient() {
    await this.clientService.demandesClient()
      .then((res: any) => {
        // console.log("allDemandesClient : ")
        // console.log(res)
        if (res) {
          this.listDemandes = res
          //location.href = '/sacs';
          //this.sacsList = res;
        }
      });
  }

  async showVirement(virement, index) {
    window.scrollTo(0, 0);
    // console.log("amineeeeeeeeee")
    // console.log(virement)
    this.selectedVirement = index
    const confirmDialog = this.dialog.open(DetailsVirementComponent, {
      data: {
        virement: virement,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });

  }

  goToNewTab(idVirement, route, index) {
    this.selectedVirement = index
    this.clientService.goToNewTab(idVirement, route)
  }
}
