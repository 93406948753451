import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-pickup-collector',
  templateUrl: './pickup-collector.component.html',
  styleUrls: ['./pickup-collector.component.css']
})
export class PickupCollectorComponent implements OnInit {

  // hourPickUp: any = null
  collectorList: any = [];

  constructor(public dialogRef: MatDialogRef<PickupCollectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private trackingServices: UsersTrackingService) { }

  ngOnInit(): void {
    this.listCollectors()
  }

  selectChangeHandler(event: any) {
    //update the ui
    // this.selectedUser = event.target.value;
    this.data.collector = event.target.value
    // console.log("event.target.value")
    // console.log(event.target.value)
  }

  async listCollectors() {
    await this.trackingServices.getCollectors().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.collectorList = res;
      }
    });
  }
}
