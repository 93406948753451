import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-tracking-louagistes',
  templateUrl: './tracking-louagistes.component.html',
  styleUrls: ['./tracking-louagistes.component.css']
})
export class TrackingLouagistesComponent implements OnInit {

  louagistesList: Observable<Array<any>>;

  constructor(private trackingServices: UsersTrackingService) { }

  ngOnInit(): void {
    this.listLouagistes()
  }

  async listLouagistes() {
    await this.trackingServices.getLouagistes().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.louagistesList = res;
      }
    });
  }

}
